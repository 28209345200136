<template>
  <div>
    <div>
      <v-card v-can="'ShipmentClientDelivery.View'" width="99%" class="mx-auto mt-3 pa-5">
        <v-row class="pa-2" dense>
          <v-col cols="12" sm="5">
            <v-row class="rounded-lg mb-3" dark dense>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined type="number" v-model="options.trackingNumber"
                  label="رقم السيستم"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined type="number" v-model="options.bookNumber" label="رقم الدفتر">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <DatePicker-layout @myEvent="options.date = $event" :date.sync="options.date" label="التاريخ ">
                </DatePicker-layout>
              </v-col>
              <v-col cols="12" sm="6">
                <DatePicker-layout @myEvent="options.deliveryDate = $event" :date.sync="options.deliveryDate"
                  label="تاريخ التسليم"></DatePicker-layout>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense outlined hide-details v-if="!loading" v-model="options.salesmanId" :items="salesmen"
                  item-text="name" item-value="id" label="المندوب"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense outlined hide-details v-if="!loading" v-model="options.externalShipperId"
                  :items="ExternalShippers" item-text="name" item-value="id" label="المخرج"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense v-model="options.manualNo" outlined type="number" label="الرقم اليدوي">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select dense hide-details v-model="options.state" outlined :items="enums.ShipmentStates"
                  item-text="text" item-value="value" label="الحالة"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense hide-details v-model="options.paymentType" outlined :items="enums.PaymentTypes"
                  item-text="text" item-value="value" label="نوع السداد"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense v-if="!loading" v-model="options.employeeId" outlined :items="avalibaleUsers"
                  item-text="name" item-value="id" label="الموظف"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense outlined hide-details v-if="!loading" auto-select-first
                  v-model="options.distributorId" :items="distributers" item-text="name" item-value="id"
                  label="الموزع"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete dense hide-details v-model="options.paymentPaidType" outlined
                  :items="enums.PaymentPaidTypes" item-text="text" item-value="value"
                  label="طريقة الدفع"></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="mr-10">
            <v-row class="rounded-lg" dark dense>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined v-model="options.sender" label="المرسل"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined v-model="options.receiver" label="المستلم"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined v-model="options.senderPhoneNumber" label="هاتف المرسل">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined v-model="options.receiverPhoneNumber" label="هاتف المستلم">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-show="!loading">
                <v-autocomplete dense outlined hide-details v-if="!loading" auto-select-first label="المسار"
                  :items="lines" item-value="id" item-text="name" v-model="options.lineId"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" v-show="!loading">
                <v-autocomplete dense v-if="!loading" outlined hide-details label="الخدمه" :items="servicesTypeList"
                  item-value="id" item-text="name" auto-select-first v-model="options.serviceTypeId"></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <DatePicker-layout @myEvent="options.startDate = $event" :date.sync="options.startDate"
                  label="التاريخ من "></DatePicker-layout>
              </v-col>
              <v-col cols="12" sm="6">
                <DatePicker-layout @myEvent="options.endDate = $event" :date.sync="options.endDate"
                  label="التاريخ الى "></DatePicker-layout>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined type="number" v-model="options.manualNoFrom" label="يدوي من">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details dense outlined type="number" v-model="options.manualNoTo" label="الي">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="8" class="mt-0">
            <v-btn @click="searchForPolisa" class="mx-3" color="success">بحث
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn class="mx-3" color="error" @click="resetOptions">تفريغ
              <i class="fa fa-trash fa-lg"></i>
            </v-btn>
            <v-btn @click="goToPrint" color="info" class="mx-3">
              طباعة <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <h5 v-html="'عدد البوالص (' + total + ')'"></h5>
        <v-row>
          <v-col cols="6">
            <v-radio-group v-model="options.state" row mandatory>
              <v-radio label="جميع البوالص" :value="-1"></v-radio>
              <v-radio :value="0" label="بوالص لم تسلم"></v-radio>
              <v-radio :value="1" label="بوالص تحت التحصيل"></v-radio>
              <v-radio :value="2" label="بوالص سلمت"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="2">
            <v-checkbox v-model="options.SMSCitySent" label="لم يتم اشعار العميل" color="green darken-3"></v-checkbox>
          </v-col>
          <v-col cols="3" v-if="options.SMSCitySent">
            <v-btn class="mx-3" color="success" @click="smsCitySend">ارسال اشعار للعملاء
              <i class="fa fa-sms fa-lg"></i>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-checkbox v-model="multiShipments" label="التسليم المتعدد للعميل" color="green darken-3"></v-checkbox>
          </v-col>
          <v-col cols="2">
            <v-checkbox v-model="options.IsInventoried" label="لم يتم جردة" color="green darken-3"></v-checkbox>
          </v-col>
        </v-row>
        <v-data-table v-model="selectedPolisa" item-key="id"
          :show-select="(multiShipments || options.SMSCitySent) ? true : false" :single-select="singleSelect"
          :headers="headers" :items="entities" fixed-header :server-items-length="total" :search="options.searchTerm"
          :items-per-page="15" :options.sync="options" :loading="loadingStates.table" class="elevation-1" :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-plus',
            nextIcon: 'mdi-minus',
            'items-per-page-text': 'عدد البوالص ' + total + '',
          }">
          <template v-slot:[`item.data-table-select`]="{ isSelected, select, item }">
            <v-simple-checkbox :ripple="false"
              v-if="options.SMSCitySent ? !item.smsCitySent : multiShipments ? item.state == 0 : false" color="green"
              :value="isSelected" @input="select($event)"></v-simple-checkbox>
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <AppState :state="item.state" :options="enums.ShipmentStates" disableMenu />
            <div v-if="item.smsCitySent" class="d-flex justify-content-center align-items-center">
              <i class="far fa-check-circle mr-2" style="color: green"></i>
              <span> تم اشعار العميل </span>
            </div>
          </template>
          <template v-slot:[`item.trackingNumber`]="{ item }">
            <div v-if="item.trackingNumber">
              <div v-if="item.trackingNumber">
                {{ item.trackingNumber }}
              </div>
              <div v-if="item.date">
                <span>
                  <v-chip style="font-size: smaller" color="green lighten-3" label>{{ item.date | ToDate }}
                  </v-chip>
                </span>
              </div>
            </div>
          </template>
          <template v-slot:[`item.details`]="{ item }">
            <v-btn v-can="'ShipmentClientDelivery.View'" color="orange" outlined elevation="2" class="mx-2"
              @click="view(item.id)" icon>
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-can="'ShipmentClientDelivery.Delete'" color="red" medium
              v-if="item.state == enums.ShipmentStates[2].value" @click="cancelDelivery(item)">fas fa-user-times
            </v-icon>
            <v-icon v-can="'ShipmentClientDelivery.Create'" color="green" medium
              v-if="item.state == enums.ShipmentStates[0].value" @click="sendTo(item)">
              fas fa-user-check
            </v-icon>
          </template>
          <template v-slot:[`item.senderInfo`]="{ item }">
            <span v-if="item.senderInfo">
              <span v-if="item.senderInfo.name">
                {{ item.senderInfo.name }}</span>
            </span>
          </template>
          <template v-slot:[`item.receiverInfo`]="{ item }">
            <div v-if="item.receiverInfo">
              <div>{{ item.receiverInfo.name }}</div>
              <span v-if="item.receiverInfo.phoneNumber != null &&
                item.receiverInfo.phoneNumber != ' '
                ">
                <v-chip color="green lighten-3" label>{{ item.receiverInfo.phoneNumber }}
                </v-chip>
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog v-model="dialog" width="50%" persistent>
        <v-card v-if="!multiShipmentsdialog">
          <v-form v-model="valid">
            <v-card-title class="headline" v-if="selectedShipment.serviceTypeId == 3">تسليم لمخرج</v-card-title>
            <v-card-title class="headline" v-if="selectedShipment.serviceTypeId != 3">تسليم لعميل</v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="mb-2" justify="center">
                  <v-col cols="4">
                    <div class="rounded-lg pa-2 text-center grey lighten-3">
                      <h4>عدد الطرود</h4>
                      <h5 v-if="selectedShipment.items">
                        {{ selectedShipment.items[0].quantity }}
                      </h5>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="rounded-lg grey pa-2 text-center lighten-3">
                      <h4>الاجمالى</h4>
                      <h5>{{ selectedShipment.totalPrice }}</h5>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="rounded-lg grey pa-2 text-center lighten-3">
                      <h4>طريقة الدفع</h4>
                      <h5>
                        <span v-if="selectedShipment.paymentType == 1">
                          <v-chip style="font-size: large" color="green lighten-3" label>{{
                            selectedShipment.paymentType
                            | PaymentTypes
                          }} /
                            مدفوع
                          </v-chip>
                        </span>
                        <span v-if="selectedShipment.paymentType == 2">
                          <v-chip style="font-size: large" color="red lighten-3" label>{{
                            selectedShipment.paymentType |
                            PaymentTypes
                          }}
                          </v-chip>
                        </span>
                      </h5>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="my-0 ammar" dense v-if="selectedShipment.serviceTypeId == 3">
                  <v-col cols="12" sm="3" class="pt-7">
                    <v-autocomplete dense outlined label="المخرجين" :items="ExternalShippers" item-value="id"
                      item-text="name" auto-select-first return-object v-model="externalShipper"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="3" class="pt-7">
                    <v-text-field outlined label="القيمه" dense type="number" hide hide-details
                      v-model="selected.amount"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" class="pt-7">
                    <v-text-field outlined label="رقم السند" dense hide hide-details v-model="selected.notice">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" class="pt-7">
                    <v-autocomplete dense outlined v-model="selected.cityId" :items="cities" label="المدينه"
                      item-text="name" item-value="id" auto-select-first></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" class="pt-8" align="center">
                    <v-btn @click="clickInput" color="info">ارفاق سند
                      <v-icon class="mr-1">mdi-camera</v-icon>
                    </v-btn>
                    <input type="file" @change="pickupImg" style="display: none" ref="fileUpload" />
                  </v-col>
                  <!-- second -->
                  <v-col cols="12" sm="12">
                    <div id="preview">
                      <img v-if="url" :src="url" />
                    </div>
                  </v-col>
                </v-row>

                <!-- second -->

                <section v-else class="recipient-delivery">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <app-auto-complete :searchFn="getRecipients" :customFilter="numberFilter" :text="'name'" v-model="selectedRecipient"
                        placeholder="بحث" class="w-100" ref="ac_selectedRecipient"></app-auto-complete>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field outlined dense label=" اسم المستلم " v-model="selected.name"
                        :rules="[allRules.required, allRules.minNameLen(2)]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field type="number" outlined dense label="رقم الهاتف" v-model="selected.phoneNumber"
                        :rules="[allRules.required, allRules.length(10)]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field type="number" outlined dense label="رقم الهويه" v-model="selected.idNumber"
                        :rules="[allRules.required, allRules.length(10)]"></v-text-field>
                    </v-col>

                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="3" v-if="selectedShipment.paymentType === 2">
                      <v-btn-toggle v-model="selected.paymentPaidType" group color="success">
                        <v-btn x-large elevation="7" value="2">
                          كاش
                        </v-btn>
                        <v-btn x-large elevation="7" value="1">
                          شبكة
                        </v-btn>
                        <v-btn x-large elevation="7" value="5">
                          كاش + شبكة
                        </v-btn>
                        <v-btn x-large elevation="7" value="3">
                          تحويل بنكي
                        </v-btn>
                        <v-btn x-large elevation="7" value="4">
                          تحت التحصيل
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row v-if="selected.paymentPaidType == 5">
                    <v-col cols="12" sm="3">
                      <v-text-field outlined label="كاش" dense type="number" hide hide-details v-model="selected.cash"
                        :rules="[allRules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field outlined label="شبكة" dense type="number" hide hide-details
                        v-model="selected.creditCard" :rules="[allRules.required]"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea rows="2" outlined label="الملاحظات" v-model="selected.notice"></v-textarea>
                    </v-col></v-row>
                </section>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn v-if="selectedShipment.serviceTypeId != 3 && selectedShipment.paymentType == 2" color="primary"
                :disabled="!valid || selected.paymentPaidType == null" @click="finished()">حفظ</v-btn>
              <v-btn v-else color="primary" :disabled="!valid" @click="finished()">حفظ</v-btn>
              <v-btn color="red darken-1" text @click="cancelEdit()">إلغاء</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
        <v-card v-else>
          <v-form v-model="valid">
            <v-card-title class="headline">تسليم متعدد لعميل</v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="mb-2" justify="center">
                  <v-col cols="4">
                    <div class="rounded-lg pa-2 text-center grey lighten-3">
                      <h4>عدد البوالص المحددة</h4>
                      <h5 v-if="selectedPolisa">
                        {{ selectedPolisa.length }}
                      </h5>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="rounded-lg pa-2 text-center grey lighten-3">
                      <h4>عدد الطرود</h4>
                      <h5 v-if="selectedPolisa">
                        {{ shipmentsquantityTotal(selectedPolisa) }}
                      </h5>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="rounded-lg grey pa-2 text-center lighten-3">
                      <h4>الاجمالى</h4>
                      <h5>{{ polisasValue(selectedPolisa) }}</h5>
                    </div>
                  </v-col>
                </v-row>
                <section class="recipient-delivery">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <app-auto-complete :searchFn="getRecipients" :customFilter="numberFilter" :text="'name'" v-model="selectedRecipient"
                        placeholder="بحث" class="w-100" ref="ac_selectedRecipient"></app-auto-complete>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field outlined dense label=" اسم المستلم " v-model="selected.name"
                        :rules="[allRules.required, allRules.minNameLen(2)]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field type="number" outlined dense label="رقم الهاتف" v-model="selected.phoneNumber"
                        :rules="[allRules.required, allRules.length(10)]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field type="number" outlined dense label="رقم الهويه" v-model="selected.idNumber"
                        :rules="[allRules.required, allRules.length(10)]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-btn-toggle v-model="selected.paymentPaidType" group color="success">
                        <v-btn x-large elevation="7" value="2">
                          كاش
                        </v-btn>
                        <v-btn x-large elevation="7" value="1">
                          شبكة
                        </v-btn>
                        <v-btn x-large elevation="7" value="3">
                          تحويل بنكي
                        </v-btn>
                        <v-btn x-large elevation="7" value="4">
                          تحت التحصيل
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea rows="2" outlined label="الملاحظات" v-model="selected.notice"></v-textarea>
                    </v-col>
                  </v-row>
                </section>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="selectedShipment.serviceTypeId != 3 && selectedShipment.paymentType == 2" color="primary"
                :disabled="!valid || selected.paymentPaidType == null" @click="finished()">حفظ</v-btn>
              <v-btn v-else-if="multiShipments && selectedPolisa.length > 0" color="primary"
                :disabled="!valid || selected.paymentPaidType == null" @click="finished()">حفظ</v-btn>
              <v-btn v-else color="primary" :disabled="!valid" @click="finished()">حفظ</v-btn>
              <v-btn color="red darken-1" text @click="cancelEdit()">إلغاء</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modals.viewShipment" width="80%" class="pa-20" ref="test" persistent>
        <v-card class="p-2">
          <ViewShipment :selected="selected" @hide="modals.viewShipment = false" ref="viewshipmentModal" />
        </v-card>
      </v-dialog>
      <v-dialog v-model="printshipment" width="20%" persistent>
        <v-card>
          <v-card-title class="headline">
            <i class="fa fa-print ml-2"></i> طباعة السند
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-btn color="success" @click="qrcodePrint()">
                    طباعة الفاتورة الضريبية المبسطة
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn color="info" @click="fulldataPrint()">
                    طباعة الفاتورة الضريبية الـشاملة
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="printshipment = false">
              إلغاء
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import lineApi from "../services/LinesService.js";
import servicesTypesApi from "../../Settings/services/ServiceTypesService";
import ShipmentService from "../services/ShipmentService";
import EmployeeService from "../../System/services/EmployeeService";
import DistributersService from "../services/DistributersService";
import ExternalShippers from "../services/ExternalShippersService";
import SalesmenService from "../services/SalesmenService";
import Enums from "../../../plugins/enums.js";
import RecipientService from "../services/RecipientService.js";
export default {
  data() {
    return {
      multiShipments: false,
      multiShipmentsdialog: false,
      selectedShipment: {},
      selectedRecipient: {},
      externalShipper: {
        cities: [],
      },
      avalibaleUsers: [
        {
          name: "admin",
          id: 1,
        },
      ],
      distributers: [
        {
          name: "admin",
          id: 1,
        },
      ],
      salesmen: [
        {
          name: "admin",
          id: 1,
        },
      ],

      modals: {
        viewShipment: false,
      },
      row: null,
      url: null,
      valid: false,
      selected: {},
      search: "",
      ExternalShippers: [],
      cities: [],
      showPrint: false,
      entities: [],
      total: 15,
      loadingStates: { table: false },
      enums: Enums,
      singleSelect: false,
      selectedPolisa: [],
      options: {
        trackingNumber: null,
        sender: null,
        receiver: null,
        senderPhoneNumber: null,
        receiverPhoneNumber: null,
        serviceTypeId: null,
        lineId: null,
        employeeId: null,
        paymentType: null,
        paymentPaidType: null,
        distributorId: null,
        externalShipperId: null,
        salesmanId: null,
        date: null,
        state: -1,
        startDate: null,
        endDate: null,
        manualNoFrom: null,
        manualNoTo: null,
        manualNo: null,
        bookNumber: null,
        duePriceReceived: null,
        hasDuePrice: null,
        otherServicesPriceReceived: null,
        hasOtherServicesPrice: null,
        deliveryDate: null,
        SMSCitySent: null,
        IsInventoried: null
      },
      dialog: false,
      printshipment: false,
      loading: false,
      lines: [],
      servicesTypeList: [],
    };
  },
  async created() {
    try {
      this.getExternalShippers();
      //const line = await lineApi.query(this.options);
      const servicesType = await servicesTypesApi.get();
      this.servicesTypeList = servicesType.data;

      EmployeeService.typeHead("", true).then((resp) => {
        this.avalibaleUsers = resp.data;
      });
      DistributersService.typeHead("", true).then((resp) => {
        this.distributers = resp.data;
      });
      SalesmenService.typeHead("", true).then((resp) => {
        this.salesmen = resp.data;
      });
      lineApi.typeHead("", true, true, false).then((resp) => {
        this.lines = resp.data;
      });
      this.getData();
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  },
  methods: {
    numberFilter(item, queryText, itemText) {
      return item.phoneNumber.toString() == queryText;
    },
    resetOptions() {
      this.options = {
        trackingNumber: null,
        sender: null,
        receiver: null,
        senderPhoneNumber: null,
        receiverPhoneNumber: null,
        serviceTypeId: null,
        lineId: null,
        employeeId: null,
        paymentPaidType: null,
        paymentType: null,
        state: null,
        distributorId: null,
        externalShipperId: null,
        salesmanId: null,
        date: null,
        state: -1,
        startDate: null,
        endDate: null,
        manualNoFrom: null,
        manualNoTo: null,
        manualNo: null,
        bookNumber: null,
        duePriceReceived: null,
        hasDuePrice: null,
        otherServicesPriceReceived: null,
        hasOtherServicesPrice: null,
        deliveryDate: null,
        SMSCitySent: null,
        IsInventoried: null
      };
    },
    shipmentsquantityTotal(items) {
      if (items != null) {
        let tottal = items.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.items[0].quantity;
        }, 0);
        return tottal;
      }
    },
    polisasValue(items) {
      let tottal = items.filter(item => {
        return item.paymentType == 2
      }).reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.totalPrice;
      }, 0);
      return tottal;
    },
    view(id) {
      ShipmentService.getById(id)
        .then((resp) => {
          this.selected = resp.data;
          this.modals.viewShipment = true;
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    searchForPolisa() {
      this.getData();
    },
    goToPrint() {
      this.options.itemsPerPage = -1;
      let routeData = this.$router.resolve({
        name: "ShipmentsReport",
        query: {
          options: JSON.stringify(this.$clean(this.options)),
          branch: true,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async smsCitySend() {
      let model = {
        shipmentsIds: this.selectedPolisa.map((e) => e.id),
      };

      ShipmentService.addToSendSMS(model)
        .then((resp) => {
          this.resetOptions();
          this.getData();
        })
        .catch((resp) => { })
        .finally(() => { });
    },
    getData() {
      this.loadingStates.table = true;
      ShipmentService.clientDelivery(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
        })
        .finally(() => {
          this.loadingStates.table = false;
        });
    },
    clickInput() {
      this.$refs.fileUpload.click();
    },
    pickupImg(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.selected.image = file;
    },
    async getExternalShippers() {
      const ExternalShipperss = await ExternalShippers.typeHead("", true);
      this.ExternalShippers = ExternalShipperss.data;
    },
    cancelEdit() {
      this.dialog = false;
      this.showPrint = false;
      (this.selectedRecipient = {}), (this.externalShipper = { cities: [] });
      this.selected = {};
      this.dialog = false;
      this.multiShipmentsdialog = false;
      this.url = null;
      if (this.$refs) this.$refs.ac_selectedRecipient.reset();
    },
    isSelectedRecipient() {
      var resp = this.selectedRecipient;
      var sel = this.selected;
      return (
        sel.name.trim() == resp.name &&
        sel.phoneNumber.trim() == resp.phoneNumber &&
        sel.idNumber.trim() == resp.idNumber
      );
    },
    qrcodePrint() {
      let routeData = this.$router.resolve({
        name: "ShipmentReceipt",
        query: { shipmentid: this.selectedShipment.id },
      });
      window.open(routeData.href, "_blank");
    },
    fulldataPrint() {
      let routeData = this.$router.resolve({
        name: "ShipmentBarCodeSingle",
        query: { shipmentid: this.selectedShipment.id },
      });
      window.open(routeData.href, "_blank");
    },
    async finished() {
      var service;
      if (this.multiShipments && this.selectedPolisa.length > 0) {
        var model = {
          ...this.selected,
          shipmentsIds: this.selectedPolisa.map((e) => e.id),
        };
        service = ShipmentService.addToRecipient(model);
      }
      else if (this.selectedShipment.serviceTypeId == 3) {
        var model = {
          ...this.selected,
          externalShipperId: this.externalShipper.id,
          shipmentId: this.selectedShipment.id,
        };
        service = ShipmentService.addToExternalShipper(model);
      } else {
        if (this.isSelectedRecipient()) {
          this.selected.RecipientId = this.selectedRecipient.id;
        }
        var model = {
          ...this.selected,
          shipmentsIds: [this.selectedShipment.id],
        };
        service = ShipmentService.addToRecipient(model);
      }

      service
        .then((resp) => {
          this.getData();
          this.$dialog.info({
            text: "هل تريد طباعة البوليصة",
            title: "  طباعة البوليصة  ",
            persistent: true,
            actions: {
              true: {
                text: "نعم",
                color: "green",
                handle: () => {
                  //this.printshipment = true;
                  //if (this.selectedShipment.receiverId != null) {
                  // let routeData = this.$router.resolve({
                  //  name: "ShipmentBarCodeSingle",
                  //   query: { shipmentid: this.selectedShipment.id },
                  //  });
                  // window.open(routeData.href, "_blank");
                  // } else {
                  if (this.multiShipments && this.selectedPolisa.length > 0) {
                    let routeData = this.$router.resolve({
                      name: "ShipmentBarCode",
                      query: { isClient: true, distributorId: 1, shipmentsIds: JSON.stringify(this.selectedPolisa.map((e) => e.id)) },
                    });
                    window.open(routeData.href, "_blank");
                    this.selectedPolisa = [];
                    this.multiShipments = false;
                  }
                  else {
                    let routeData = this.$router.resolve({
                      name: "ShipmentReceipt",
                      query: { shipmentid: this.selectedShipment.id },
                    });
                    window.open(routeData.href, "_blank");
                  }
                  //    }
                },
              },
              false: {
                text: "الغاء",
              },
            },
          });
        })
        .catch((resp) => {
          this.$dialog.notify.error(resp.request.response, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .finally(() => {
          (this.selectedRecipient = {}),
            (this.externalShipper = { cities: [] });
          this.selected = {};
          this.dialog = false;
          this.multiShipmentsdialog = false;
          this.url = null;
          if (this.$refs) this.$refs.ac_selectedRecipient.reset();
        });
    },
    cancelDelivery(item) {
      this.$dialog.info({
        text: "هل أنت متاكد من حذف و ارجاع تسليم البوليصة",
        title: "  ارجاع بوليصة  ",
        persistent: true,
        actions: {
          true: {
            text: "نعم",
            color: "green",
            handle: () => {
              ShipmentService.cancelDelivery(item.id)
                .then((resp) => {
                  this.getData();
                  this.$dialog.notify.success("تم ارجاع البوليصة بنجاح", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((error) => {
                  this.$dialog.notify.error(error.request.response, {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .finally();
            },
          },
          false: {
            text: "الغاء",
          },
        },
      });
    },
    sendTo(item) {
      if (this.multiShipments && this.selectedPolisa.length > 0)
        this.multiShipmentsdialog = true;
      else
        this.selectedShipment = item;

      this.dialog = true;
    },
    getRecipients(searchTerm) {
      return RecipientService.typeHead(searchTerm, false);
    },
  },
  watch: {
    selectedRecipient() {
      this.selected = { ...this.selectedRecipient };
    },
    externalShipper: {
      handler() {
        this.cities = this.externalShipper.cities.map((e) => {
          return { ...e.city, id: e.id };
        });
      },
    },
    search: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    "multiShipments": {
      handler(newValue) {
        if (newValue == false) {
          this.multiShipmentsdialog = false;
          this.multiShipments = false;
          this.selectedPolisa = [];
        }
      },
      deep: true,
    }
  },
  computed: {
    headers() {
      let headers = [
        {
          text: "رقم",
          align: "start",
          sortable: false,
          value: "trackingNumber",
          align: "center",
        },
        {
          text: "الرقم اليدوى",
          value: "manualNo",
          align: "center",
          sortable: false,
        },
        {
          text: "المرسل",
          value: "senderInfo",
          align: "center",
          sortable: false,
        },
        {
          text: "المستلم",
          value: "receiverInfo",
          align: "center",
          sortable: false,
        },
        {
          text: "اجمالي السعر",
          value: "totalPrice",
          align: "center",
          sortable: false,
        },
        { text: "الحاله", value: "state", align: "center", sortable: true },
        { text: "التفاصيل", value: "details", align: "center", sortable: true },
      ];

      if (this.options.state == -1 || this.options.state == 0) {
        headers.push({
          text: "تسليم لعميل",
          value: "actions",
          align: "center",
          sortable: false,
        });
      }
      return headers;
    },
  },
};
</script>
<style lang="scss" scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 300px;
  max-height: 200px;
}

::v-deep .theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: right !important;
}

input[type="checkbox"][id^="myCheckbox"] {
  display: none;
}

label {
  border: 5px solid #fff;
  padding: 10px;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  height: 40px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked+label {
  border-color: rgb(17, 219, 108);
}

:checked+label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:checked+label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}
</style>